import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

const SingleAnnouncement = ({ announcements, backgroundColor }) => {
  return (
    <div
      className="hellobar_container"
      style={{
        backgroundColor: `${backgroundColor}`,
        color: `${backgroundColor === '#ffffff' ? 'black' : 'white'}`,
      }}
    >
      <Swiper spaceBetween={0} centeredSlides={true} effect="cube" modules={[Autoplay]}>
        {announcements.map((announcement, index) => {
          return (
            <SwiperSlide
              style={{
                display: 'flex',
                width: '100vw',
                alignItems: 'center',
              }}
              key={index}
            >
              {announcement?.url !== '' ? (
                <a
                  href={announcement.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    listStyle: 'none',
                    textDecoration: 'none',
                  }}
                >
                  <span
                    style={{
                      marginRight: '15px',
                      color: `${backgroundColor === '#ffffff' ? 'black' : 'white'}`,
                    }}
                  >
                    {announcement.title}
                  </span>
                  <img
                    alt="Right Button"
                    src={
                      backgroundColor === '#ffffff'
                        ? '/assets/icons/arrow-hellobar-icon-black.svg'
                        : '/assets/icons/arrow-hellobar-icon-white.svg'
                    }
                    className={`arrow-btns img h2 mr2 pointer`}
                    style={{
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </a>
              ) : (
                <span style={{ marginRight: '15px' }}>{announcement.title}</span>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const MultipleAnnouncement = ({ announcements, backgroundColor }) => {
  return (
    <div
      className="hellobar_container"
      style={{
        backgroundColor: `${backgroundColor}`,
        color: `${backgroundColor === '#ffffff' ? 'black' : 'white'}`,
      }}
    >
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        effect="cube"
        loop={true}
        speed={800}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay]}
      >
        {announcements.map((announcement, index) => {
          return (
            <SwiperSlide
              style={{ display: 'flex', width: '100vw', alignItems: 'center' }}
              key={index}
            >
              {announcement?.url !== '' ? (
                <a
                  href={announcement.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    listStyle: 'none',
                    textDecoration: 'none',
                  }}
                >
                  <span
                    style={{
                      marginRight: '15px',
                      color: `${backgroundColor === '#ffffff' ? 'black' : 'white'}`,
                    }}
                  >
                    {announcement.title}
                  </span>
                  <img
                    alt="Right Button"
                    src={
                      backgroundColor === '#ffffff'
                        ? '/assets/icons/arrow-hellobar-icon-black.svg'
                        : '/assets/icons/arrow-hellobar-icon-white.svg'
                    }
                    className={`arrow-btns img h2 mr2 pointer`}
                    style={{
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </a>
              ) : (
                <span
                  style={{
                    marginRight: '15px',
                    display: 'flex',
                  }}
                >
                  {announcement.title}
                </span>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default function SwiperComponent({ announcementsConfig }) {
  const { announcements = [], backgroundColor = '#000' } = announcementsConfig;
  if (announcements?.length === 0) return null;
  return announcements?.length === 1 ? (
    <SingleAnnouncement announcements={announcements} backgroundColor={backgroundColor} />
  ) : (
    <MultipleAnnouncement
      announcements={announcements}
      backgroundColor={backgroundColor}
    />
  );
}
