import React from 'react';
import SwiperComponent from './SwiperComponent';
import { HELLO_BAR_HIDDEN_STATUS } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';

interface HelloBarConfig {
  status: number;
  color_hex: string;
  title: string;
  url: string;
}

interface HelloBarWidgets {
  widgets: HelloBarConfig[];
}
const getAnnouncementsFromConfig = (config: HelloBarWidgets) => {
  if (config?.widgets) {
    const { status, color_hex = null } = config?.widgets[0];

    const announcements = config?.widgets?.map(widget => ({
      title: widget.title,
      url: widget.url,
    }));
    return {
      status,
      color_hex,
      announcements,
    }
  } else {
    return {};
  }
}
const SubHeader = props => {
  const bodyComponents = useSSRSelector(state => state.storeReducer.store?.theme?.components?.Body) || [];
  const headerConfig = bodyComponents.find(comp => comp.sub_type === 'HelloBar')?.config;
  const { config = headerConfig } = props;

  const { status, color_hex, announcements } = getAnnouncementsFromConfig(config);

  return status !== HELLO_BAR_HIDDEN_STATUS ? (
    <SwiperComponent
      announcementsConfig={{
        backgroundColor: color_hex,
        announcements: announcements,
      }}
    />
  ) : null;
};

export default SubHeader;
